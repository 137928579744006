<template>
  <b-card>
    <div
      v-if="state.loading.value"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <data-table
      v-else
      :columns="state.columns"
      :rows.sync="state.rows"
      :settings="state.settings"
      :dev-logs="false"
      @updateFilter="doUpdateFilter"
    >
      <template v-slot:header-button-left>
        <div class="mt-1">
          <strong>Итого в кассе: </strong><span
            v-if="Math.sign(state.totally.total_sum) === -1"
            class="font-weight-bolder text-danger"
          > {{ new Intl.NumberFormat('ru-RU').format(state.totally.total_sum) + '₽' }} </span>
          <span
            v-else
            class="font-weight-bolder text-success"
          > {{ new Intl.NumberFormat('ru-RU').format(state.totally.total_sum) + '₽' }} </span>
        </div>
      </template>
      <template v-slot:header-button>
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          no-flip
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form class="py-1">
            <b-form-group
              label="Организация"
              label-for="dropdown-form-name"
              @submit.prevent.stop
            >
              <b-form-select
                v-model="filter.organization_id"
                :options="organizations"
                value-field="id"
                text-field="name"
              />
            </b-form-group>
            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filter.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>
        <router-link :to="{name: 'kassa-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>

      </template>

      <template v-slot:table-row="item">
        <span v-if="item.column.field === 'v_totally'">
          <span
            v-if="Math.sign(item.row.pko - item.row.rko) === -1"
            class="font-weight-bolder text-danger"
          > {{ new Intl.NumberFormat('ru-RU').format(item.row.pko - item.row.rko) + '₽' }} </span>
          <span
            v-else
            class="font-weight-bolder text-success"
          > {{ new Intl.NumberFormat('ru-RU').format(item.row.pko - item.row.rko) + '₽' }} </span>

        </span>
        <span
          v-else-if="item.column.field === 'action'"
          class="float-right"
        >
          <router-link :to="{name: 'kassa-show', params: {id: item.row.id}}">
            <b-button
              variant="flat-primary"
              class="btn-icon"
            ><feather-icon
              icon="EyeIcon"
            />
            </b-button></router-link>
        </span>
      </template>
    </data-table>
  </b-card>
</template>
<script>
import DataTable from '@/components/DataTable.vue'
import {
  BFormGroup, BCard, BDropdown, BDropdownForm, BButton, BButtonGroup, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import filter from '@/mixins/filter'
// import Vue from 'vue'
// import store from '@/store'
import store from '@/store'
import axiosIns from '@/libs/axios'
import { categoriesSetup } from './index'

export default {
  components: {
    BCard,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BButton,
    DataTable,
    BButtonGroup,
    BFormSelect,
    BSpinner,
  },
  mixins: [filter],
  data() {
    return {
      organizations: null,
    }
  },
  setup() {
    const state = categoriesSetup()
    return { state }
  },
  beforeMount() {
    this.loadOrganizations()
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    loadOrganizations() {
      const req = axiosIns({
        method: 'GET',
        url: '/organizations/short-list',
      })

      req.then(response => {
        const data = [{ id: null, name: ' — ' }]
        data.push(...response.data.data)
        this.organizations = data
      })

      return req
    },
  },
}
</script>
