import { reactive, ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import store from '@/store'
import _ from 'lodash'
// import Vue from 'vue'
import {
  settingsObject, setFilterFn, watchFilter, setStateSettings, setDefaultFilterFn,
} from '@/helpers/filter'

export const categorySetup = () => {
  const state = reactive({
    categoryData: null,
    rootCategories: null,
    loadCategory: null,
  })

  const loading = ref(true)

  state.loadCategory = categoryId => {
    loading.value = true
    axiosIns({
      method: 'GET',
      url: `/kassa/${categoryId}`,
    })
      .then(response => {
        state.categoryData = response.data.data
      })
      .catch(error => {
        console.log(error)
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => {
        store.commit('app/UPDATE_CURRENT_PAGE_TITLE', `${state.categoryData.name}`)
        loading.value = false
      })
  }
  state.loadRootCategories = () => {
    loading.value = true

    axiosIns({
      method: 'GET',
      url: '/categories/getAllRoot',
    })
      .then(response => {
        const defaultCategory = [{ id: null, name: '' }]
        state.rootCategories = _.merge(defaultCategory, response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  return { ...state, loading }
}

// eslint-disable-next-line consistent-return
function format(inputDate) {
  const date = new Date(inputDate)
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(date.getTime())) {
    return `${(date.getDate() + 1) < 10 ? `0${date.getDate()}` : date.getDate()}.${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}.${date.getFullYear()}`
  }
}

export const categoriesSetup = () => {
  const state = reactive({
    // categories: null,
    columns: [
      {
        label: 'Дата',
        field: row => format(row.date),
      },
      {
        label: 'Приход',
        field: row => `${new Intl.NumberFormat('ru-RU').format(row.pko)}₽`,
      },
      {
        label: 'Расход',
        field: row => `${new Intl.NumberFormat('ru-RU').format(row.rko)}₽`,
      },
      {
        label: 'Итог',
        field: 'v_totally',
      },
      {
        label: 'Действия',
        field: 'action',
      },
    ],
    rows: [],
    settings: settingsObject(),
    filters: {},
    defaultFilters: {},

    // Methods
    loadCategories: null,

    totally: {
      total_rko: 0,
      total_pko: 0,
      total_all: 0,
    },
  })
  const loading = ref(true)

  state.loadCategories = (defaultFilters = null) => {
    if (defaultFilters !== null) {
      state.defaultFilters = defaultFilters
    }

    // Vue.set(state, 'loading', 0)
    loading.value = true

    axiosIns({
      method: 'GET',
      url: '/cashiers',
      params: _.merge(defaultFilters, state.filters),
    })
      .then(response => {
        const responseData = response.data.data
        state.totally.total_rko = response.data.totally.rko
        state.totally.total_pko = response.data.totally.pko
        state.totally.total_sum = response.data.totally.total
        setStateSettings(state, responseData)

        state.rows.splice(0, state.rows.length)
        state.rows.push(...response.data.data.data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        loading.value = false
        // Vue.set(state, 'loading', true)
      })
  }
  state.setFilter = setFilterFn(state)
  state.setDefaultFilter = setDefaultFilterFn(state)

  watchFilter(state)

  return { ...state, loading }
}

export default {
  categorySetup,
  categoriesSetup,
}
